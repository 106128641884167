import { useRef } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import cn from 'classnames'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'
import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik'
import { get } from 'lodash'
import * as uuid from 'uuid'

import {
  EnrichedApplianceWithOwner,
  EnrichedInputPort,
  EnrichedOutputPort,
  PaginatedRequestParams,
  PhysicalPortInfoWithAppliance,
} from '../../../api/nm-types'
import { Api, GlobalState } from '../../../store'
import {
  Address,
  Appliance,
  ApplianceType,
  ApplianceVersion,
  BuildInfo,
  Group,
  IpPortMode,
  ListResult,
  PortBase,
  PortMode,
  Region,
  RegionalPort,
} from 'common/api/v1/types'
import { BackendValidation, GridItem, Paper } from '../../common/Form'

import { AutoComplete } from '../AutoComplete'
import { EnrichedOutputWithEnrichedPorts } from '../../outputs/Edit'
import { EnrichedInputWithEnrichedPorts } from '../../inputs/Edit'
import { RegionalInterfaceSection } from './Regional'
import { ApplianceInterfaceSection } from './Appliance'
import { runningDifferentSoftwareVersion } from '../../../utils'
import DelayedAppearance from '../DelayedAppearance'
import { CircularProgress, FormControl, InputLabel, MenuItem, Select as MUISelect } from '@mui/material'
import { InterfaceUnavailableReason, whyIsPhysicalPortUnavailable } from '../../../utils/physicalPortUtil'
import { PortForm as InputPortForm } from '../../inputs/Edit/PortForm'
import { PortForm as OutputPortForm } from '../../outputs/Edit/PortForm'
import { applianceTypes } from 'common/applianceTypes'
import { REACT_APP_EDGE_PRODUCT } from '../../../env'

export const applianceSectionPaper = {
  padding: (theme: Theme) => theme.spacing(0, 0, 2),
  margin: (theme: Theme) => theme.spacing(4, 0, 0),
}

export const styles = {
  warningText: {
    marginLeft: (theme: Theme) => theme.spacing(1),
    color: (theme: Theme) => theme.palette.warning.light,
  },
}

export const APPLIANCE_SECTION_FORM_PREFIX = '_applianceSection'

export type ApplianceSectionSelectionData<T extends EnrichedInputPort | EnrichedOutputPort> = {
  region?: Pick<Region, 'id' | 'name'>
  appliance?: Pick<Appliance, 'id' | 'name' | 'type'> & Partial<Pick<Appliance, 'settings'>>
  ports: T[]
}

export const formatInterfaceAddress = (address: Address) => {
  const hasPublicAddress = !!address.publicAddress
  return `${address.address}${hasPublicAddress ? ` Public: ${address.publicAddress}` : ''}`
}

function getPortApplianceId<T extends EnrichedInputPort | EnrichedOutputPort>(port: T) {
  return isRegionalPort(port) ? port.region!.allocatedAppliance!.id : port._port.appliance.id
}

export const groupPortsByApplianceOrRegion = <T extends EnrichedInputPort | EnrichedOutputPort>(
  ports: T[],
): { [key: string]: ApplianceSectionSelectionData<T> } => {
  const portsGroupedByApplianceOrRegion = ports.reduce(
    (portsGroupedByApplianceOrRegion: { [key: string]: ApplianceSectionSelectionData<T> }, port: T) => {
      const isRegion = isRegionalPort(port)
      // Prefer '_appliance' to 'appliance' since it contains settings
      const applianceOrRegion = isRegion ? port.region! : port._port._appliance ?? port._port.appliance
      const portApplianceId = getPortApplianceId(port)
      const existingEntry = Object.entries(portsGroupedByApplianceOrRegion).find(([_, v]) => {
        return !!v.ports.find((p) => getPortApplianceId(p) === portApplianceId)
      })
      if (existingEntry) {
        existingEntry[1].ports.push(port)
      } else {
        const [key, entry] = makeApplianceSection({
          region: isRegion ? applianceOrRegion : undefined,
          appliance: isRegion ? undefined : (applianceOrRegion as any),
        })
        entry.ports.push(port)
        // eslint-disable-next-line no-param-reassign
        portsGroupedByApplianceOrRegion[key] = entry as any
      }
      return portsGroupedByApplianceOrRegion
    },
    {},
  )
  if (Object.keys(portsGroupedByApplianceOrRegion).length === 0) {
    // Ensure at least 1 appliance section
    const [key, applianceSection] = makeApplianceSection<T>({ region: undefined, appliance: undefined })
    portsGroupedByApplianceOrRegion[key] = applianceSection
  }
  return portsGroupedByApplianceOrRegion
}

export const makeApplianceSection = <T extends EnrichedInputPort | EnrichedOutputPort>({
  region,
  appliance,
}: {
  region: Pick<Region, 'id' | 'name'> | undefined
  appliance: (Pick<Appliance, 'id' | 'name' | 'type'> & Partial<Pick<Appliance, 'settings'>>) | undefined
}): [string, ApplianceSectionSelectionData<T>] => [
  `${APPLIANCE_SECTION_FORM_PREFIX}-${uuid.v4()}`,
  {
    region,
    appliance,
    ports: [],
  },
]

export const collectPortsFromApplianceSections = <T extends EnrichedInputPort | EnrichedOutputPort>(
  values: object,
): T[] => {
  return collectApplianceSectionEntries(values).flatMap(([_, value]) => value.ports as T[])
}

export const collectApplianceSectionEntries = <T extends EnrichedInputPort | EnrichedOutputPort>(
  values: object,
): Array<[string, ApplianceSectionSelectionData<T>]> =>
  Object.entries(values).filter(([key]) => key.startsWith(APPLIANCE_SECTION_FORM_PREFIX))

/// Business logic regarding multi-appliance inputs and outputs.
export const isApplianceOrRegionSelectable = (
  applianceOrRegion: ApplianceOrRegion,
  values: ApplianceSectionForm,
  isOutputForm: boolean,
) => {
  const isRegion = !isAppliance(applianceOrRegion)
  const applianceSectionEntries = collectApplianceSectionEntries(values)
  const numberOfApplianceSections = applianceSectionEntries.length
  if (numberOfApplianceSections <= 1) {
    // Single appliance section:
    // User may or may not yet have selected an appliance or region - either way he/she is free to select/change it.
    return true
  }

  // Multiple appliance sections:
  const selectedAppliancesAndRegions = applianceSectionEntries.map(([_k, v]) => v.region ?? v.appliance)
  const firstSelectedApplianceOrRegion = selectedAppliancesAndRegions.find(Boolean)
  if (!firstSelectedApplianceOrRegion) {
    // User has two empty appliance sections but with no appliance or region selected (can happen if they clear the selected value)
    if (isOutputForm) {
      // For output: Must select a region or a core appliance (multi-appliance outputs are restricted to core nodes)
      return isRegion || applianceOrRegion.type === ApplianceType.core
    }
    // For input: free to select any appliance or region
    return true
  }
  if (isAppliance(firstSelectedApplianceOrRegion)) {
    // User has previously selected an appliance
    if (isRegion) {
      // The second selection must also be an appliance (not a region)
      return false
    }
    const isApplianceAlreadySelected = !!selectedAppliancesAndRegions.find(
      (a) => a && isAppliance(a) && a.id === applianceOrRegion.id,
    )
    if (isApplianceAlreadySelected) {
      // The second appliance must be a different one
      return false
    }

    if (firstSelectedApplianceOrRegion.type === ApplianceType.core) {
      // The second appliance must be of the same type, where type is defined to be either a "core appliance" (located in the cluster)
      // or "edge appliance" (located outside of the cluster).
      return applianceOrRegion.type === ApplianceType.core
    } else {
      if (isOutputForm) {
        // For output: Multi-appliance outputs are restricted to core nodes
        return false
      }
      // For input: The second appliance must also be a non-core type
      return applianceOrRegion.type !== ApplianceType.core
    }
  }

  // User has previously selected a region - the second selection must also be a region
  return isRegion
}

export type ApplianceOrRegion = Pick<Appliance, 'id' | 'name' | 'type' | 'settings'> | Pick<Region, 'id' | 'name'>
export type ApplianceSectionForm = EnrichedInputWithEnrichedPorts | EnrichedOutputWithEnrichedPorts

interface FormProps {
  index: number
  namePrefix: string
  title: string
  onRemove?: (namePrefix: string) => void
  isInputForm: boolean
  isEditingExistingEntity: boolean
  isCopyingExistingEntity: boolean
  inputId: string | undefined
  outputId: string | undefined
  groupId: Group['id']
  initialApplianceOrRegionId: string | undefined
  enforcedPortMode: PortMode | undefined
  isModeSelectionDisabled: boolean
  adminStatus: 0 | 1
  isApplianceOrRegionSelectable: (applianceOrRegion: ApplianceOrRegion) => boolean
  onApplianceOrRegionSelected: (applianceOrRegion: ApplianceOrRegion | null) => void
}

export const Loading = ({ message }: { message: string }) => (
  <DelayedAppearance gracePeriodMs={1000}>
    <GridItem newLine>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <Typography component="div" variant="body1">
          {message}
        </Typography>
        <CircularProgress style={{ marginLeft: 20 }} />
      </div>
    </GridItem>
  </DelayedAppearance>
)
export const Error = ({ message }: { message: string }) => (
  <GridItem newLine>
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
      }}
    >
      <Typography component="div" variant="body1">
        {message}
      </Typography>
    </div>
  </GridItem>
)

export function LogicalPortForm<T extends EnrichedInputPort | EnrichedOutputPort | RegionalPort>({
  isRegional,
  namePrefix,
  logicalPorts,
  isInputForm,
  adminStatus,
  appliancePhysicalPorts,
  isModeSelectionDisabled,
  enforcedPortMode,
  restrictPhysicalPortsToGroupId,
  onAddLogicalPortRequested,
}: {
  isRegional: boolean
  namePrefix: string
  logicalPorts: T[]
  isInputForm: boolean
  adminStatus: 0 | 1
  appliancePhysicalPorts: PhysicalPortInfoWithAppliance[]
  isModeSelectionDisabled: boolean
  enforcedPortMode: PortMode | undefined
  restrictPhysicalPortsToGroupId?: string
  onAddLogicalPortRequested: () => void
}) {
  return (
    <FieldArray
      name={`${namePrefix}.ports`}
      render={(formikArrayHelpers: FieldArrayRenderProps) => {
        return logicalPorts.map((logicalPort, portIndex) => {
          const physicalPort = appliancePhysicalPorts.find((p) => p.id == logicalPort.physicalPort)
          if (!physicalPort) return null
          const isPrimaryInterface = portIndex === 0
          const interfaceText = `${isRegional ? 'Regional ' : ''}Interface${
            isPrimaryInterface ? '' : `-${portIndex + 1}`
          }`
          const interfaceToolTip = isPrimaryInterface
            ? undefined
            : `Additional interface to ${isInputForm ? 'receive' : 'egress'} the same stream for redundancy.`
          return (
            <Paper key={logicalPort.id} sx={applianceSectionPaper}>
              <GridItem tooltip={interfaceToolTip} newLine>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel id={`select-input-interface-${portIndex}-label`}>{interfaceText}</InputLabel>
                  <MUISelect
                    name={`${formikArrayHelpers.name}.${portIndex}.interface`}
                    label={interfaceText}
                    labelId={`select-input-interface-${portIndex}-label`}
                    disabled={logicalPort.mode === IpPortMode.generator}
                    fullWidth
                    value={logicalPort.physicalPort}
                    onChange={(event) => {
                      const newPhysicalPort = appliancePhysicalPorts.find((p) => p.id === event.target.value)
                      formikArrayHelpers.replace(portIndex, {
                        ...logicalPort,
                        physicalPort: newPhysicalPort?.id,
                        _port: newPhysicalPort,
                      })
                    }}
                  >
                    {appliancePhysicalPorts.map((physicalPort) => {
                      const disabledReason = whyIsPhysicalPortUnavailable({
                        requestee: { groupId: restrictPhysicalPortsToGroupId },
                        physicalPort,
                      })
                      const isPhysicalPortEnabled =
                        !disabledReason ||
                        (adminStatus === 0 && disabledReason.code === InterfaceUnavailableReason.occupied)
                      const formattedAddress = physicalPort.addresses.map(formatInterfaceAddress)[0]
                      return (
                        <MenuItem key={physicalPort.id} value={physicalPort.id} disabled={!isPhysicalPortEnabled}>
                          <div>
                            <Typography>
                              {physicalPort.name}
                              {disabledReason?.msg && (
                                <Box component={'span'} sx={styles.warningText}>
                                  ({disabledReason.msg})
                                </Box>
                              )}
                            </Typography>
                            {formattedAddress && (
                              <Typography component="div" variant="body2" color="textSecondary">
                                {formattedAddress}
                              </Typography>
                            )}
                          </div>
                        </MenuItem>
                      )
                    })}
                  </MUISelect>
                </FormControl>
              </GridItem>

              {isInputForm && (
                <InputPortForm
                  index={portIndex}
                  namePrefix={`${formikArrayHelpers.name}.${portIndex}`}
                  physicalPort={physicalPort}
                  isModeDisabled={isModeSelectionDisabled}
                  form={formikArrayHelpers.form}
                  onAddLogicalPortRequested={onAddLogicalPortRequested}
                  onRemoveLogicalPortRequested={formikArrayHelpers.remove}
                />
              )}
              {!isInputForm && (
                <OutputPortForm
                  index={portIndex}
                  namePrefix={`${formikArrayHelpers.name}.${portIndex}`}
                  physicalPort={physicalPort}
                  isModeDisabled={isModeSelectionDisabled}
                  enforcedPortMode={enforcedPortMode}
                  form={formikArrayHelpers.form}
                  onAddLogicalPortRequested={onAddLogicalPortRequested}
                  onRemoveLogicalPortRequested={() => formikArrayHelpers.remove(portIndex)}
                />
              )}
            </Paper>
          )
        })
      }}
    />
  )
}

export const isRegionalPort = (port?: PortBase): port is RegionalPort =>
  !!port && 'region' in port && !!(port as RegionalPort).region

export const isAppliance = (
  applianceOrRegion: ApplianceOrRegion,
): applianceOrRegion is Pick<Appliance, 'id' | 'name' | 'type' | 'settings'> => 'type' in applianceOrRegion

export function isCoreNode(values: ApplianceSectionForm) {
  for (const { region, appliance } of collectApplianceSectionEntries(values).map(([_, value]) => value)) {
    if (region) return true
    if (appliance?.type === ApplianceType.core) return true
  }
  return false
}

const getApplianceSoftwareString = (
  version: ApplianceVersion,
  buildInfo: BuildInfo | undefined,
): string | undefined => {
  const isApplianceRunningOutdatedSoftware =
    runningDifferentSoftwareVersion(version.controlSoftwareVersion, buildInfo) ||
    runningDifferentSoftwareVersion(version.dataSoftwareVersion, buildInfo)
  return isApplianceRunningOutdatedSoftware ? '(appliance software is outdated)' : undefined
}

export const areMultipleLogicalPortsPerApplianceSupported = ({
  portMode,
  region,
  appliance,
  isInput,
}: {
  portMode?: string
  region?: Pick<Region, 'id' | 'name'>
  appliance?: Pick<Appliance, 'type'>
  isInput: boolean
}) => {
  const supportedModes: string[] = isInput
    ? [IpPortMode.rtp, IpPortMode.udp, IpPortMode.srt]
    : [IpPortMode.rtp, IpPortMode.srt]
  const isModeSupported = supportedModes.includes(portMode ?? '')
  const isTypeSupported =
    region || ([ApplianceType.edgeConnect, ApplianceType.core] as string[]).includes(appliance?.type ?? '')
  return isModeSupported && isTypeSupported
}

export const ApplianceSection = <T extends ApplianceSectionForm>(form: FormikProps<T> & FormProps) => {
  const {
    namePrefix,
    onRemove,
    title,
    status,
    isApplianceOrRegionSelectable,
    onApplianceOrRegionSelected,
    enforcedPortMode,
    isModeSelectionDisabled,
    values,
    isInputForm,
    adminStatus,
  } = form
  const { buildInfo } = useSelector(({ buildInfoReducer }: GlobalState) => buildInfoReducer, shallowEqual)

  const childRef = useRef<{
    onAddInterfaceButtonClicked: Function
  }>(null)

  const {
    region,
    appliance,
    ports: logicalPorts,
  }: ApplianceSectionSelectionData<EnrichedInputPort | EnrichedOutputPort> = get(values, namePrefix)

  const logicalPort1 = logicalPorts[0]
  const areMultipleLogicalPortsSupported = areMultipleLogicalPortsPerApplianceSupported({
    isInput: isInputForm,
    appliance,
    region,
    portMode: logicalPort1?.mode,
  })
  const maxNumberOfLogicalPortsAllowed = areMultipleLogicalPortsSupported ? 2 : 1
  const canAddMoreLogicalPorts = !!(region || appliance) && logicalPorts.length < maxNumberOfLogicalPortsAllowed
  // Don't allow manually (using the button) adding logical ports for SRT outputs since it is handled automatically
  const canAddMoreLogicalPortsManually =
    canAddMoreLogicalPorts && (isInputForm || logicalPorts.every((lp) => lp.mode !== IpPortMode.srt))

  const onAddLogicalPortRequested = () => {
    if (!canAddMoreLogicalPorts) return
    childRef.current?.onAddInterfaceButtonClicked()
  }

  return (
    <Paper
      id={`applianceSectionContainer-${form.index}`}
      title={title}
      className={cn('outlined', status?.port && 'error')}
      actionsPane={[
        ...(canAddMoreLogicalPortsManually
          ? [
              <>
                <Button
                  data-test-id={`add-interface-${form.index}`}
                  variant="contained"
                  color="secondary"
                  onClick={onAddLogicalPortRequested}
                >
                  Add interface
                </Button>
                <BackendValidation name="port" form={form} />
              </>,
            ]
          : []),
        ...(onRemove
          ? [
              <Button
                key={'remote-input-appliance'}
                variant="outlined"
                color="primary"
                onClick={() => onRemove(namePrefix)}
              >
                {` Remove ${isInputForm ? 'input' : 'output'} appliance `}
              </Button>,
            ]
          : []),
      ]}
    >
      <Paper>
        <GridItem>
          <AutoComplete<ApplianceOrRegion>
            placeholder={`${isInputForm ? 'Input' : 'Output'} appliance or region`}
            groupBy={(option) => (isAppliance(option) ? 'Appliance' : 'Region')}
            isClearable={true}
            initialValue={isRegionalPort(logicalPort1) ? logicalPort1.region! : region ?? appliance ?? null}
            onValueSelected={(selected: ApplianceOrRegion | null) => {
              const didReselectCurrentValue =
                (region && selected?.id === region.id) ||
                (appliance && selected?.id === appliance.id) ||
                (!selected && !region && !appliance)
              if (didReselectCurrentValue) return
              onApplianceOrRegionSelected(selected)
            }}
            getOptionLabel={(value: ApplianceOrRegion) => {
              if (!isAppliance(value)) return value.name
              const appliance = value as EnrichedApplianceWithOwner
              const applianceSoftwareOutdatedString =
                appliance.version && getApplianceSoftwareString(appliance.version, buildInfo)
              return `${appliance.name}${applianceSoftwareOutdatedString ? ` ${applianceSoftwareOutdatedString}` : ''}`
            }}
            renderOption={(props, option) => {
              const applianceSoftwareOutdatedString =
                isAppliance(option) &&
                'version' in option &&
                getApplianceSoftwareString((option as EnrichedApplianceWithOwner).version, buildInfo)
              return (
                <li {...props}>
                  <Typography component="div" variant="body2" color="textSecondary">
                    <>
                      {option.name}
                      {applianceSoftwareOutdatedString && (
                        <Box sx={styles.warningText}>{applianceSoftwareOutdatedString}</Box>
                      )}
                    </>
                  </Typography>
                </li>
              )
            }}
            isOptionDisabled={(option) => !isApplianceOrRegionSelectable(option)}
            api={async (
              params: PaginatedRequestParams<any>,
            ): Promise<ListResult<EnrichedApplianceWithOwner | Region>> => {
              // TODO: Remove usage of static/compiled applianceTypes and add support for filtering on input/output capabilities in listAppliances instead.
              const hasInputCapabilities = (type: ApplianceType) =>
                applianceTypes[type](REACT_APP_EDGE_PRODUCT).input !== undefined
              const hasOutputCapabilities = (type: ApplianceType) =>
                applianceTypes[type](REACT_APP_EDGE_PRODUCT).output !== undefined
              const onlyVideoRegions = true
              const regions = await Api.regionApi.getRegions(params, onlyVideoRegions)
              const appliances = await Api.appliancesApi.getAppliances({
                ...params,
                types: Object.values(ApplianceType)
                  .filter(isInputForm ? hasInputCapabilities : hasOutputCapabilities)
                  .join(','),
              })
              return {
                items: [...regions.items, ...appliances.items],
                total: regions.total + appliances.total,
                skip: Number(params.pageNumber) * Number(params.rowsPerPage),
                limit: Number(params.rowsPerPage),
              }
            }}
            dataTestId={`${form.index}-appliance`}
          />
        </GridItem>

        {region && (
          <RegionalInterfaceSection
            {...form}
            selectedRegion={region}
            enforcedPortMode={enforcedPortMode}
            isModeSelectionDisabled={isModeSelectionDisabled}
            logicalPorts={logicalPorts}
            myRef={childRef}
            onAddLogicalPortRequested={onAddLogicalPortRequested}
          />
        )}

        {!region && appliance && (
          <ApplianceInterfaceSection
            {...form}
            adminStatus={adminStatus}
            selectedAppliance={appliance}
            logicalPorts={logicalPorts}
            enforcedPortMode={enforcedPortMode}
            isModeSelectionDisabled={isModeSelectionDisabled}
            myRef={childRef}
            onAddLogicalPortRequested={onAddLogicalPortRequested}
          />
        )}
      </Paper>
    </Paper>
  )
}
