import { isAsyncThunkAction, isPending, type CaseReducer } from '@reduxjs/toolkit'

/** Set state.loading=true if more pending actions have been dispatched than fulfilled/rejected for the specified asyncThunks. In other words, there is a request pending. */
export function createLoadingReducer<S extends { loading: boolean }>(...asyncThunks: Parameters<typeof isPending>[0]) {
  let pendingCount = 0

  const pending = isPending(...asyncThunks)
  const isLoadingAction = isAsyncThunkAction(...asyncThunks)

  const loadingReducer: CaseReducer<S> = (state, action) => {
    if (pending(action)) {
      pendingCount++
    } else {
      pendingCount--
      if (pendingCount < 0) {
        pendingCount = 0
      }
    }

    const silent = action.meta?.arg?.silent as boolean | undefined
    const loading = silent ? false : pendingCount !== 0
    return { ...state, loading }
  }

  return { isLoadingAction, loadingReducer }
}
