import { useEffect } from 'react'
import { FormikProps } from 'formik'
import { get } from 'lodash'

import {
  Address,
  ApplianceFeatures,
  ApplianceType,
  ApplianceVersion,
  Input,
  InputAdminStatus,
  InputPort,
  IpInputPort,
  IpPortMode,
  OccupiedPort,
} from 'common/api/v1/types'

import { Select } from '../../../../common/Form'
import UdpForm, { UdpFields } from './UdpForm'
import RtpForm, { RtpFields } from './RtpForm'
import SrtForm, { getSrtFieldsToSave } from './SrtForm'
import ZixiForm, { getZixiFieldsToSave } from './ZixiForm'
import RistForm, { RistFields } from './RistForm'
import GeneratorForm, { GeneratorFields } from './GeneratorForm'
import RtmpForm, { RtmpFields } from '../../../../inputs/Edit/PortForm/IpPortForm/RtmpForm'
import { isTcpBasedProtocol, isUdpBasedProtocol } from 'common/ports'

export { udpDefaults } from './UdpForm'
export { rtpDefaults } from './RtpForm'
export { srtDefaults } from './SrtForm'
export { ristDefaults } from './RistForm'
export { zixiDefaults } from './ZixiForm'
export { generatorDefaults } from './GeneratorForm'

export enum CommonFields {
  mode = 'mode',
  physicalPort = 'physicalPort',
  copies = 'copies',
  region = 'region',
  applianceAllocationId = 'applianceAllocationId',
}

export const getIpPortFormFields = (port: InputPort) => {
  const common = [
    'id',
    CommonFields.mode,
    CommonFields.physicalPort,
    CommonFields.copies,
    CommonFields.region,
    CommonFields.applianceAllocationId,
  ]
  if (port.mode === IpPortMode.udp) return [...common, ...Object.keys(UdpFields)]
  if (port.mode === IpPortMode.generator) return [...common, ...Object.keys(GeneratorFields)]
  if (port.mode === IpPortMode.rtp) return [...common, ...Object.keys(RtpFields)]
  if (port.mode === IpPortMode.rist) return [...common, ...Object.keys(RistFields)]
  if (port.mode === IpPortMode.zixi) return [...common, ...getZixiFieldsToSave(port)]
  if (port.mode === IpPortMode.rtmp) return [...common, ...Object.keys(RtmpFields)]
  if (port.mode === IpPortMode.srt) {
    if (port.srtMode) return [...common, ...getSrtFieldsToSave(port)]
  }
  return []
}

export type IpInput = Input

interface IpPortFormProps {
  form: FormikProps<IpInput>
  applianceType: ApplianceType
  applianceVersion: ApplianceVersion
  applianceFeatures: ApplianceFeatures
  index: number
  namePrefix: string
  addresses: Address[]
  occupiedPorts: OccupiedPort[]
  isModeDisabled: boolean
  onAddLogicalPortRequested: () => void
}

const IpPortForm = ({
  form,
  addresses,
  applianceType,
  applianceVersion,
  applianceFeatures,
  namePrefix,
  index,
  occupiedPorts,
  isModeDisabled,
  onAddLogicalPortRequested,
}: IpPortFormProps) => {
  const { values, setFieldValue } = form
  const logicalPort: IpInputPort = get(values, namePrefix)
  const adminStatus = get(values, 'adminStatus') ? InputAdminStatus.on : InputAdminStatus.off
  const handoverMethod = get(form.values, 'handoverMethod')

  const currentMode = logicalPort.mode as '' | IpInputPort['mode']

  const modes =
    applianceFeatures.input?.modes
      .filter((m) => m.mode in IpPortMode)
      .map((m) => ({
        name: m.prettyName ?? m.mode,
        value: m.mode,
      })) ?? []
  const modeValues = modes.map((m) => m.value)

  const modeKey = `${namePrefix}.mode`
  useEffect(() => {
    if (currentMode !== '') {
      const isValidMode = modes.some((m) => m.value === currentMode)
      if (!isValidMode) {
        setFieldValue(modeKey, '', false)
      }
    }
  }, [JSON.stringify(modeValues), currentMode, setFieldValue, modeKey])

  const occupiedUdpPorts = occupiedPorts.filter((o) => isUdpBasedProtocol(o.portMode))
  const occupiedTcpPorts = occupiedPorts.filter((o) => isTcpBasedProtocol(o.portMode))
  return (
    <>
      <Select
        label="Mode"
        name={modeKey}
        newLine
        required
        disabled={isModeDisabled}
        options={modes}
        validators={{
          oneOf: { validValues: new Set(modeValues) },
        }}
      />
      {currentMode === IpPortMode.udp && (
        <UdpForm
          namePrefix={namePrefix}
          addresses={addresses}
          form={form}
          applianceType={applianceType}
          occupiedPorts={occupiedUdpPorts}
          adminStatus={adminStatus}
        />
      )}
      {currentMode === IpPortMode.rtp && (
        <RtpForm
          namePrefix={namePrefix}
          addresses={addresses}
          applianceType={applianceType}
          applianceVersion={applianceVersion}
          index={index}
          occupiedPorts={occupiedUdpPorts}
          adminStatus={adminStatus}
          form={form}
        />
      )}
      {currentMode === IpPortMode.srt && (
        <SrtForm
          namePrefix={namePrefix}
          applianceType={applianceType}
          applianceFeatures={applianceFeatures}
          index={index}
          addresses={addresses}
          occupiedPorts={occupiedUdpPorts}
          adminStatus={adminStatus}
          form={form}
          onAddLogicalPortRequested={onAddLogicalPortRequested}
        />
      )}
      {currentMode === IpPortMode.rist && (
        <RistForm
          namePrefix={namePrefix}
          addresses={addresses}
          applianceType={applianceType}
          occupiedPorts={occupiedUdpPorts}
          adminStatus={adminStatus}
          form={form}
        />
      )}
      {currentMode === IpPortMode.rtmp && (
        <RtmpForm
          namePrefix={namePrefix}
          addresses={addresses}
          applianceType={applianceType}
          occupiedPorts={occupiedTcpPorts}
          adminStatus={adminStatus}
          form={form}
        />
      )}
      {currentMode === IpPortMode.zixi && (
        <ZixiForm
          applianceType={applianceType}
          applianceFeatures={applianceFeatures}
          applianceVersion={applianceVersion}
          namePrefix={namePrefix}
          addresses={addresses}
          form={form}
        />
      )}
      {currentMode === IpPortMode.generator && (
        <GeneratorForm
          namePrefix={namePrefix}
          addresses={addresses}
          occupiedPorts={occupiedUdpPorts}
          adminStatus={adminStatus}
          form={form}
          handoverMethod={handoverMethod!}
        />
      )}
    </>
  )
}

export default IpPortForm
