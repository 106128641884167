import { useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Typography from '@mui/material/Typography'
import {
  Dashboard,
  OndemandVideoOutlined,
  PowerSettingsNew,
  Settings,
  VideocamOutlined,
  Warning,
  Person,
} from '@mui/icons-material'
import { Theme, useTheme } from '@mui/material/styles'

import { AlarmWithImpact, BuildInfo, CiBuildInfo, EdgeProduct, Role, User } from 'common/api/v1/types'
import { logoutAndNavigateToMainPage, stopImpersonation } from '../../../redux/actions/userActions'
import { toggleSidebarMenu } from '../../../redux/actions/uiActions'
import { AppDispatch, GlobalState } from '../../../store'
import Logo from '../../common/Logo'

import MenuItem, { Item } from './MenuItem'
import { useMobile } from '../../../utils/index'
import DevModeSwitch from './DevModeSwitch'
import AlarmItemNameWithActiveAlarms from './AlarmItemNameWithActiveAlarms'
import { Routes } from '../../../utils/routes'
import { useRoutes } from '../../../store'
import { REACT_APP_EDGE_PRODUCT } from '../../../env'

const menuItems: (
  role: Role,
  alarms: AlarmWithImpact[],
  routes: Routes,
) => Array<(Item & { iconColorFn?: (uiReducer: GlobalState['uiReducer'], theme: Theme) => string | false }) | null> = (
  userRole,
  alarms,
  routes,
) => [
  {
    name: 'Overview',
    url: routes.overview(),
    icon: <Dashboard />,
  },
  {
    name: 'Inputs',
    url: routes.inputs(),
    icon: <VideocamOutlined />,
  },
  {
    name: 'Outputs',
    url: routes.outputs(),
    icon: <OndemandVideoOutlined />,
  },
  // {
  //   name: 'Event overview',
  //   url: routes.geo(),
  //   icon: <Map />,
  // },
  {
    name: <AlarmItemNameWithActiveAlarms alarms={alarms} />,
    url: routes.alarms(),
    icon: <Warning />,
  },
  {
    name: 'Settings',
    icon: <Settings />,
    children: [
      {
        name: 'Regions',
        url: routes.regions(),
      },
      {
        name: 'Appliances',
        url: routes.appliances(),
      },
      {
        name: 'Interfaces',
        url: routes.interfaces(),
        divider: true,
      },
      userRole === Role.super
        ? {
            name: 'Networks',
            url: routes.networks(),
          }
        : null,
      userRole === Role.super
        ? {
            name: 'Address mappings',
            url: routes.addressMappings(),
          }
        : null,
      userRole === Role.super && REACT_APP_EDGE_PRODUCT !== EdgeProduct.loci
        ? {
            name: 'Kubernetes nodes',
            url: routes.kubernetesNodes(),
          }
        : null,
      userRole === Role.super && REACT_APP_EDGE_PRODUCT !== EdgeProduct.loci
        ? {
            name: 'Kubernetes services',
            url: routes.services(),
            divider: true,
          }
        : null,
      {
        name: 'Groups',
        url: routes.groups(),
      },
      {
        name: 'Users',
        url: routes.users(),
        divider: true,
      },
      {
        name: 'Audit log',
        url: routes.audit(),
      },
      userRole === Role.super
        ? {
            name: 'Alarm history',
            url: routes.alarmLogs(),
            divider: true,
          }
        : null,
      userRole === Role.super
        ? {
            name: 'Billing',
            url: routes.billing(),
          }
        : null,
      userRole === Role.super || userRole === Role.admin
        ? {
            name: 'Usage',
            url: routes.usage(),
            divider: true,
          }
        : null,
      userRole === Role.super
        ? {
            name: 'Global settings',
            url: routes.globalSettings(),
          }
        : null,
    ].filter(Boolean),
  },
]

const styles = {
  open: {
    transition: (theme: Theme) =>
      theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
  },
  close: {
    transition: (theme: Theme) =>
      theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    width: 0,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  logoWrapper: {
    padding: (theme: Theme) => theme.spacing(1, 2),
  },
  list: {
    overflowY: 'auto',
  },
  menuBottom: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-end',
  },
  version: {
    fontSize: '10px',
    padding: (theme: Theme) => theme.spacing(1, 2),
  },
}

const isCiBuild = (buildInfo: BuildInfo): buildInfo is CiBuildInfo => buildInfo.ciBuild

interface AppVersionProps {
  buildInfo?: BuildInfo
}

const AppVersion = ({ buildInfo }: AppVersionProps) => {
  if (!buildInfo) {
    return <></>
  }

  const commit = isCiBuild(buildInfo) && buildInfo.ciCommitShortSha ? `, ${buildInfo.ciCommitShortSha}` : ''
  const buildTime = buildInfo.buildTime.toISOString().slice(0, 19)
  const versionInfo = `${buildInfo.release}, ${buildTime}${commit}`

  return <Typography sx={styles.version}>{versionInfo}</Typography>
}

export const Sidebar = () => {
  const theme = useTheme()
  const history = useHistory()
  const { uiReducer, buildInfo, user, alarms } = useSelector(
    ({ uiReducer, buildInfoReducer, userReducer, alarmsReducer }: GlobalState) => ({
      uiReducer,
      buildInfo: buildInfoReducer.buildInfo,
      user: userReducer.user as User,
      alarms: alarmsReducer.alarmsForNotifications,
    }),
    shallowEqual,
  )

  const { open } = uiReducer
  const { isMobile } = useMobile()
  const dispatch = useDispatch<AppDispatch>()
  const logoutUserAction = () => void dispatch(logoutAndNavigateToMainPage())
  const stopImpersonationAction = () => void dispatch(stopImpersonation())
  const routes = useRoutes()
  const menu = (
    <Box id="sidebar" sx={styles.container}>
      <Box sx={styles.logoWrapper}>
        <Logo />
      </Box>
      <Divider />
      <List id="menu" disablePadding sx={styles.list}>
        {menuItems(user.role, alarms, routes).map((item, ind) => {
          if (!item) return null
          item.iconColor = item.iconColorFn ? item.iconColorFn(uiReducer, theme) : undefined

          return <MenuItem item={item} key={item.url || ind} location={history.location.pathname} />
        })}
      </List>
      <Divider />
      <Box sx={styles.menuBottom}>
        <ListItem>
          <ListItemText>
            <Typography variant="body2" component="div" data-test-id="current-user">
              {user.username}
            </Typography>
            <Typography variant="body2" component="div">
              {user.impersonatedBy && (
                <span style={{ opacity: 0.5 }}>
                  (by <span data-test-id="current-impersonator">{user.impersonatedBy.username}</span>)
                </span>
              )}
            </Typography>
          </ListItemText>
        </ListItem>
        <Divider />
        {user.impersonatedBy ? (
          <MenuItem
            item={{ name: `Switch to admin`, icon: <Person /> }}
            id="stop-impersonation"
            action={stopImpersonationAction}
          />
        ) : (
          <MenuItem
            item={{ name: 'Log out', icon: <PowerSettingsNew /> }}
            key="/logout"
            id="logout-button"
            action={logoutUserAction}
          />
        )}
        <DevModeSwitch>
          <AppVersion buildInfo={buildInfo} />
        </DevModeSwitch>
      </Box>
    </Box>
  )
  const onToggle = () => dispatch(toggleSidebarMenu())
  return isMobile ? (
    <SwipeableDrawer anchor="left" open={open} onOpen={onToggle} onClose={onToggle}>
      {menu}
    </SwipeableDrawer>
  ) : (
    <Drawer variant="persistent" anchor="left" open={open} sx={open ? styles.open : styles.close}>
      {menu}
    </Drawer>
  )
}
