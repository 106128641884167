import cn from 'classnames'
import { useDispatch } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { PowerSettingsNew, Person } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'

import Logo from '../common/Logo'
import { logoutAndNavigateToMainPage, stopImpersonation } from '../../redux/actions/userActions'
import { useUser } from '../../utils'
import { AppDispatch } from 'src/store'

const styles = {
  toolbar: {
    justifyContent: 'space-between',
  },
  right: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    marginLeft: (theme: Theme) => theme.spacing(3),
    // fontSize: (theme: Theme) => theme.typography.body2.fontSize, // TODO: error?
    '&:hover': {
      color: (theme: Theme) => theme.palette.primary.main,
    },
  },
  buttonText: (theme: Theme) => ({
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  }),
}

const TopBar = () => {
  const user = useUser()
  const dispatch = useDispatch<AppDispatch>()
  const logoutUserAction = () => void dispatch(logoutAndNavigateToMainPage())
  const stopImpersonationAction = () => void dispatch(stopImpersonation())

  return (
    <AppBar position="static">
      <Toolbar sx={styles.toolbar} id="stream-manager-bar">
        <Logo className={cn({ maxWidth: '50%' })} />

        <Box sx={styles.right}>
          <Typography variant="body2" data-test-id="current-user">
            {user.username}
          </Typography>
          <Typography variant="body2">
            {user.impersonatedBy && (
              <span style={{ opacity: 0.5, marginLeft: 4 }}>
                (by <span data-test-id="current-impersonator">{user.impersonatedBy.username}</span>)
              </span>
            )}
          </Typography>
          {user.impersonatedBy ? (
            <Typography
              variant="body2"
              color="textSecondary"
              sx={styles.button}
              onClick={stopImpersonationAction}
              id="stop-impersonation"
            >
              <Box sx={styles.buttonText}>Switch to admin</Box>
              <Person />
            </Typography>
          ) : (
            <Typography variant="body2" color="textSecondary" sx={styles.button} onClick={logoutUserAction}>
              <Box sx={styles.buttonText}>Log out</Box>
              <PowerSettingsNew />
            </Typography>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
