import { createSlice } from '@reduxjs/toolkit'

import { Appliance, Input, InputGraph, LimitedAppliance, MetricWindow, ServiceOverview } from 'common/api/v1/types'
import { getService, clearService, selectTr101290Window } from '../actions/serviceOverviewActions'
import { logoutUser } from '../actions/userActions'
import { createLoadingReducer } from './shared'

export interface ServiceOverviewState {
  input?: Input
  loading: boolean
  graph: InputGraph
  appliances: (Appliance | LimitedAppliance)[]
  tunnels: ServiceOverview['objects']['tunnels']
  outputs: ServiceOverview['objects']['outputs']
  tr101290Window: MetricWindow
}
export const initialStateServiceOverview: ServiceOverviewState = {
  loading: false,
  graph: {
    nodes: {},
    edges: [],
  },
  appliances: [],
  tunnels: [],
  outputs: { items: [], total: 0 },
  tr101290Window: MetricWindow.m1,
}

type State = ServiceOverviewState
const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(getService)

const serviceOverviewSlice = createSlice({
  name: 'serviceOverview',
  initialState: initialStateServiceOverview,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getService.fulfilled,
        (
          state,
          {
            payload: {
              input,
              graph,
              objects: { tunnels, appliances, outputs },
            },
          },
        ) => ({
          ...state,
          input: input || state.input,
          graph,
          appliances,
          tunnels,
          outputs,
        }),
      )
      .addCase(selectTr101290Window.pending, (state, { meta: { arg: tr101290Window } }) => ({
        ...state,
        tr101290Window,
      }))
      .addCase(clearService, (prevState) => ({
        ...initialStateServiceOverview,
        tr101290Window: prevState.tr101290Window,
      }))
      .addCase(logoutUser.fulfilled, () => initialStateServiceOverview)
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default serviceOverviewSlice.reducer
