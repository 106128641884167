import Box from '@mui/material/Box'
import { Theme } from '@mui/material/styles'

import Inputs from './Inputs'
import Outputs from './Outputs'

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
  },
  inputs: (theme: Theme) => ({
    width: '70%',
    height: '100%',
    flexGrow: 1,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  }),
  outputs: (theme: Theme) => ({
    width: '30%',
    height: '100%',
    flexGrow: 1,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  }),
}

const List = () => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.inputs}>
        <Inputs />
      </Box>
      <Box sx={styles.outputs}>
        <Outputs />
      </Box>
    </Box>
  )
}

export default List
