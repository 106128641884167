import { IAlarmApi } from './api'
import { MOCK_DELAY_MS } from '../MockData'
import type { AlarmUpdate, AlarmWithImpact, ListResult } from 'common/api/v1/types'
import type { EdgeClient } from 'common/generated/edgeClient'

export class MockAlarmApi implements IAlarmApi {
  readAlarmsInBackground(): Promise<AlarmWithImpact[]> {
    return new Promise((resolve) =>
      setTimeout(() => {
        return resolve([])
      }, MOCK_DELAY_MS),
    )
  }

  getAlarms(): Promise<ListResult<AlarmWithImpact>> {
    return Promise.resolve({ total: 0, items: [] })
  }

  async updateAlarm(_alarmId: AlarmWithImpact['alarmId'], _params: AlarmUpdate): ReturnType<EdgeClient['setAlarm']> {
    return new Promise((resolve) =>
      setTimeout(
        () =>
          resolve({
            id: 'bigint(1234567)',
          }),
        MOCK_DELAY_MS,
      ),
    ) as any
  }
}
