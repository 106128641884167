import { FC, ReactNode, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { Menu } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'

import { toggleSidebarMenu } from '../../redux/actions/uiActions'
import { useDebouncedFilter, usePageParams } from '../../utils'
import { AppDispatch, GlobalState } from '../../store'
import SearchInput from './SearchInput'
import { PaginatedRequestParams } from '../../api/nm-types'

// Styles to be applied on the <Pagination> component's closest scrollable ancestor
// in order to make it able to float/be sticky.
const floatingPaginationStyles = {
  position: 'relative',
  height: '100vh',
  width: '100%',
  maxWidth: '1920px',
  margin: 'auto',
} as const

const styles = {
  spacer: {
    flexGrow: 1,
  },
  search: (theme: Theme) => ({
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }),
}

export interface WrapperProps {
  name: string
  entityName?: string
  searchbarPlaceholder?: string
  children: ReactNode
}
/**
 * Common wrapper for edge manager page content
 * @param entityName - to show the name of the entity (input name for example) we opened
 * @param name - the name of the page
 * @param searchbarPlaceholder
 * @param children - content of the page
 */
const Wrapper: FC<WrapperProps> = ({ entityName, name, searchbarPlaceholder, children }) => {
  const dispatch = useDispatch<AppDispatch>()
  const ToggleSidebarMenuAction = () => dispatch(toggleSidebarMenu())
  const { isSearchable } = useSelector(({ uiReducer }: GlobalState) => uiReducer, shallowEqual)

  const [urlParams, setUrlParams] = usePageParams<Pick<PaginatedRequestParams, 'filter' | 'pageNumber'>>()
  const { filter: urlBarFilter = '' } = urlParams
  const [searchBarFilter, setSearchBarFilter] = useState(urlBarFilter)

  useDebouncedFilter(searchBarFilter, (debouncedSearchBarFilter) => {
    // Update "filter" key in url params when search bar changes
    if (urlBarFilter !== debouncedSearchBarFilter) {
      setUrlParams({ filter: debouncedSearchBarFilter, pageNumber: '0' })
    }
  })
  useEffect(() => {
    // Update search bar when "filter" key in url params changes
    if (urlBarFilter !== searchBarFilter) {
      setSearchBarFilter(urlBarFilter)
    }
  }, [urlBarFilter])

  return (
    <section style={floatingPaginationStyles}>
      <AppBar position="relative" data-test-id="app-bar">
        <Toolbar disableGutters>
          <IconButton edge="start" color="inherit" aria-label="Open drawer" onClick={ToggleSidebarMenuAction}>
            <Menu />
          </IconButton>

          {entityName ? (
            <Typography component="h1" variant="h2" color="inherit" noWrap>
              {name || 'Content dashboard'} / {entityName}
            </Typography>
          ) : (
            <Typography component="h1" variant="h2" color="inherit" noWrap>
              {name || 'Content dashboard'}
            </Typography>
          )}

          <Box sx={styles.spacer} />
          {!!isSearchable && (
            <Box sx={styles.search}>
              <SearchInput
                initialValue={searchBarFilter}
                onChange={(input) => setSearchBarFilter(input)}
                placeholder={searchbarPlaceholder}
                stretchable
              />
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Grid container>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </section>
  )
}

export default Wrapper
