import { Redirect, Route, Switch } from 'react-router-dom'
import Box from '@mui/material/Box'

import { Role } from 'common/api/v1/types'
import StreamManagerMain from '../streamManager'
import { useUser } from '../../utils'

import { useRoutes } from '../../store'

const styles = {
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    overflowY: 'overlay' as 'scroll',
  },
}

export const StreamManagerContainer = () => {
  const user = useUser()
  const routes = useRoutes()

  return (
    <Box sx={styles.content}>
      <Switch>
        <Route path={routes.stream.route} component={StreamManagerMain} />
        {user.role === Role.basic ? (
          <Route>
            <Redirect to={routes.stream()} />
          </Route>
        ) : (
          <Redirect exact from="/" to={routes.overview()} />
        )}
      </Switch>
    </Box>
  )
}
