import { useCallback, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Form, Formik, FormikProps } from 'formik'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { Api, AppDispatch, GlobalState } from '../../store'
import Pendable from '../common/Pendable'
import { Autocomplete, ButtonsPane, GridItem, Paper, SafeRouting } from '../common/Form'
import { Appliance, ApplianceType, Group, Network } from 'common/api/v1/types'
import {
  alarmsThatDisablePort,
  getInterfaceName,
  isEditableGroup,
  isEditableInterface,
  useConfirmationDialog,
  useUser,
} from '../../utils'
import Wrapper from '../common/Wrapper'
import DataSet from '../common/DataSet'
import { Link } from '../common/Link'
import { clearPort, getPort, updatePort } from '../../redux/actions/portActions'
import { EnrichedPhysicalPort } from '../../api/nm-types'

import { useRoutes } from '../../store'

const InterfaceForm = (
  formik: FormikProps<EnrichedPhysicalPort>,
  history: RouteComponentProps['history'],
  port: EnrichedPhysicalPort,
) => {
  const routes = useRoutes()
  const user = useUser()
  const isSaving = useSelector(({ portsReducer }: GlobalState) => portsReducer.saving, shallowEqual)
  useEffect(() => {
    if (isSaving === false) formik.setSubmitting(false)
  }, [isSaving])
  const portDisabledAlarms = alarmsThatDisablePort(port._appliance, port.id)

  const portOwnerId = formik.values._owner.id
  const applianceOwnerId = formik.values._appliance?._owner.id
  const isCoreAppliance =
    formik.values._appliance?.type === ApplianceType.core || formik.values._appliance?.type === ApplianceType.thumb

  const isUserAllowedToEdit = applianceOwnerId && isEditableInterface(applianceOwnerId, user)
  const groupsApi = useCallback(Api.groupsApi.getPureGroups.bind(Api.groupsApi), [])
  const networksApi = useCallback(Api.networksApi.getNetworks.bind(Api.networksApi), [])
  return (
    <Grid container>
      <Grid item xs={12}>
        <SafeRouting enabled={formik.dirty && !formik.isSubmitting} />
        <Form id="interface-form" translate="no" noValidate>
          <Paper title="Meta data">
            <GridItem lg={12} xl={12}>
              <DataSet
                values={{
                  Name: formik.values.name,
                  Appliance: formik.values.appliance.name,
                  Address: formik.values.addresses[0]?.address,
                  'Public address': formik.values.addresses[0]?.publicAddress,
                  Owner: (
                    <Link
                      to={routes.groupsUpdate({ id: portOwnerId })}
                      underline="hover"
                      available={isEditableGroup(portOwnerId, user)}
                    >
                      {formik.values._owner.name}
                    </Link>
                  ),
                }}
              />
            </GridItem>
          </Paper>

          <Paper title={getInterfaceName(port)}>
            <GridItem lg={12} xl={12}>
              <Autocomplete<Group>
                name="owner"
                formik={formik}
                api={groupsApi}
                defaultOption={formik.values._owner}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                optionComparator={(o1, o2) => o1.id == o2.id}
                disabled={isCoreAppliance || !applianceOwnerId || !isUserAllowedToEdit}
                required={true}
              />
            </GridItem>
            {portDisabledAlarms && portDisabledAlarms.length > 0
              ? portDisabledAlarms.map((portAlarm, i) => {
                  return (
                    <GridItem key={portAlarm.alarmId.concat(i.toString())} xs={12} lg={12} xl={12}>
                      <Typography component="div" variant="body2" color="textSecondary">
                        {portAlarm.text}
                      </Typography>
                    </GridItem>
                  )
                })
              : ''}
          </Paper>

          <Paper title={'Networks'}>
            <GridItem
              lg={12}
              xl={12}
              tooltip="Interfaces within the same network will be connected using their private IP addresses"
            >
              <Autocomplete<Network>
                name="networks"
                label="Networks"
                formik={formik}
                disabled={!isUserAllowedToEdit}
                api={networksApi}
                defaultOption={formik.values.networks}
                // 'getOptionValue' is actually called with an array since 'defaultOption' provides an array (and multiple is set to true)
                getOptionValue={(option) => (Array.isArray(option) ? option : option.id)}
                getOptionLabel={(option) => option.name}
                optionComparator={(o1, o2) => o1.id == o2.id}
                multiple
                xs={4}
              />
            </GridItem>
          </Paper>

          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => {
                  history.push(routes.interfaces())
                },
              },
              Save: { primary: true, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

export const Edit = ({ history, match }: RouteComponentProps<{ id: string }>) => {
  const dispatch = useDispatch<AppDispatch>()
  const showConfirmation = useConfirmationDialog()

  useEffect(() => {
    match.params.id && dispatch(getPort(match.params.id))
    return () => {
      dispatch(clearPort())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const portToEdit = useSelector(({ portsReducer }: GlobalState) => portsReducer.port, shallowEqual)
  if (!portToEdit) return null
  const portAppliance = portToEdit._appliance as Appliance

  const onSubmit = (owner: Group['id'], networks: Network[]) => {
    const isChangingPortOwner = owner !== portToEdit.owner

    const action = () =>
      void dispatch(
        updatePort({
          appliance: portAppliance,
          sharedPorts: portAppliance.physicalPorts.map((port) => ({
            port: port.id,
            owner: port.id === portToEdit.id ? owner : port.owner,
            networks: networks?.map((n) => n.id),
          })),
        }),
      )

    if (isChangingPortOwner) {
      showConfirmation(
        action,
        'You are changing owner of an interface. All inputs and outputs on this interface will be cleared. Are you sure you want to proceed?',
      )
    } else {
      action()
    }
  }

  return (
    <Wrapper name="Interfaces" entityName={portToEdit ? getInterfaceName(portToEdit) : 'New'}>
      <Grid container spacing={0}>
        <Pendable pending={!portToEdit}>
          <Formik
            onSubmit={(values) => {
              onSubmit(values.owner, values.networks)
            }}
            initialValues={{
              ...portToEdit,
              _owner: portToEdit._owner || '',
            }}
          >
            {(formik) => InterfaceForm(formik, history, portToEdit)}
          </Formik>
        </Pendable>
      </Grid>
    </Wrapper>
  )
}
