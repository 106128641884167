import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import { RouteComponentProps } from 'react-router-dom'
import { mergeWith, omit, omitBy, pick } from 'lodash'
import Grid from '@mui/material/Grid'

import {
  Appliance,
  AudioStream,
  ComprimatoPortMode,
  EncoderSettings,
  GlobalSettings,
  Input,
  InputAdminStatus,
  InputInit,
  InputPort,
  IpPortMode,
  PhysicalPort,
  PIDRule,
  PIDRuleAction,
  Role,
  SrtMode,
  ThumbnailMode,
  VideoPreviewMode,
} from 'common/api/v1/types'
import { clearInput, createInput, getInput, updateInput } from '../../../redux/actions/inputsActions'
import { Api, AppDispatch, GlobalState, useRoutes } from '../../../store'
import { formTransform, useConfirmationDialog, usePageParams, useUser } from '../../../utils'
import Pendable from '../../common/Pendable'
import Wrapper from '../../common/Wrapper'

import { CommonFields, getIpPortFormFields } from './PortForm/IpPortForm'

import InputForm, { initialInputLogicalPort } from './InputForm'
import {
  EnrichedInput,
  EnrichedInputPort,
  EnrichedInputWithPorts,
  EnrichedPhysicalPort,
  SrtBondingMode,
} from '../../../api/nm-types'
import {
  collectPortsFromApplianceSections,
  groupPortsByApplianceOrRegion,
  isCoreNode,
} from '../../common/Interface/Base'
import { isIpPort } from 'common/api/v1/helpers'
import { enqueueErrorSnackbar } from '../../../redux/actions/notificationActions'
import { distinct, whitelistCidrBlockToArray } from 'common/utils'

export interface EnrichedInputWithEnrichedPorts extends EnrichedInput {
  ports?: Array<InputPort & { _port: EnrichedPhysicalPort & { _appliance: Appliance } }>
  _derived: boolean
}

const getInitialState = (
  selectedInput: EnrichedInputWithPorts | undefined,
  isCopy: boolean,
  pageParams: Record<string, string | undefined>,
  settings: GlobalSettings | undefined,
  parentInput: Input | null,
): EnrichedInputWithEnrichedPorts => {
  const broadcastStandard =
    parentInput?.broadcastStandard || selectedInput?.broadcastStandard || settings?.defaultBroadcastStandard || 'dvb'
  const handoverMethod =
    parentInput?.handoverMethod || selectedInput?.handoverMethod || settings?.defaultHandoverMethod || 'udp'
  const receiver = {
    name: '',
    maxBitrateMbps: (selectedInput?.maxBitrate && selectedInput?.maxBitrate / 10 ** 6) || '',
    tr101290Enabled: true,
    broadcastStandard: selectedInput?.tr101290Enabled === false ? 'none' : broadcastStandard,
    thumbnailMode: true,
    videoPreviewMode: selectedInput?.previewSettings?.mode || VideoPreviewMode.ondemand,
    adminStatus: true,
    ports: [],
    downstreamAppliances: [],
    handoverMethod,
    _redundant: !!(selectedInput?.ports?.[0]?.copies === 2),
    bufferSize: 6000,
    deriveFrom: {
      ingestTransform: selectedInput?.deriveFrom?.ingestTransform || {
        services: [],
      },
      parentInput: pageParams.deriveFrom || selectedInput?.deriveFrom?.parentInput || '',
    },
    parentInput: '',
    _derived: !!selectedInput?.deriveFrom?.parentInput || !!pageParams.deriveFrom,
  }

  mergeWith(
    receiver,
    omit(selectedInput, ['metrics', 'alarms', 'broadcastStandard']),
    (_: any, existingValueForKey: any, key: any) => {
      if (key === 'thumbnailMode') {
        return existingValueForKey === ThumbnailMode.core
      }
      if (key === 'adminStatus') {
        return existingValueForKey === InputAdminStatus.on
      }
      if (key === 'ports') {
        const failoverPriorities = existingValueForKey
          .map((p: any) => p.failoverPriority)
          .filter((p: any) => typeof p === 'number')
        const numDistinctFailoverPriorities = new Set(failoverPriorities).size

        return existingValueForKey.map((port: EnrichedInputPort & { _port: EnrichedPhysicalPort }) =>
          mergeWith(
            initialInputLogicalPort({
              physicalPortId: port.physicalPort,
              port: port._port,
              numDistinctFailoverPriorities,
            }),
            port,
            (_, existingValueForKey2, key2) => {
              if ('totalBitrate' === key2 && existingValueForKey2) {
                return existingValueForKey2 / 1000000 // bps --> Mbps
              }
              if ('reducedBitrateThreshold' === key2 && existingValueForKey2) {
                return existingValueForKey2 / 1000
              }
              if ('whitelistCidrBlock' === key2 && existingValueForKey2) {
                // EDGE-3975: Use array-representation rather than string representation to avoid
                // dialog "You have some unsaved data, do you really want to leave the page?"
                // due to initialValues !== values despite no changes being made.
                return whitelistCidrBlockToArray(existingValueForKey2)
              }
            },
          ),
        )
      }
    },
  )
  if (isCopy) {
    receiver.name += ' (copy)'
  }

  // 1. Divide ports into groups by their appliance or region, using key '_applianceSection-${id}'
  // 2. We will later create one <ApplianceSection> per group, passing the above key as namePrefix
  // 3. Each ApplianceSection and sub-component modifies their nameprefix-XXX entry
  // 4. When the user submits, we will merge each namePrefix-entry back into the 'ports' again
  const portsGroupedByApplianceOrRegion = groupPortsByApplianceOrRegion(receiver.ports)
  return { ...receiver, ...portsGroupedByApplianceOrRegion } as unknown as EnrichedInputWithEnrichedPorts
}

export const Edit = ({ history, match }: RouteComponentProps<{ id?: string }>) => {
  const routes = useRoutes()
  const inputId = match.params.id
  const user = useUser()
  const [parameters] = usePageParams()
  const dispatch = useDispatch<AppDispatch>()
  const [parentInput, setParentInput] = useState<Input | null>(null)

  useEffect(() => {
    inputId && dispatch(getInput(inputId))
    return () => {
      dispatch(clearInput())
    }
  }, [dispatch, inputId])

  const selectedInput = useSelector(({ inputsReducer }: GlobalState) => inputsReducer.input, shallowEqual)
  const showConfirmation = useConfirmationDialog()

  useEffect(() => {
    if (parameters.deriveFrom) {
      Api.inputApi
        .getInput(parameters.deriveFrom)
        .then((input) => {
          setParentInput(input)
        })
        .catch((err) => {
          dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch parent input' }))
          history.goBack()
        })
    }
  }, [parameters.deriveFrom])

  const { settings } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)
  if (selectedInput && user.group !== selectedInput.owner && user.role !== Role.super) {
    history.goBack()
    return null
  }

  const initialState = getInitialState(
    selectedInput,
    match.path === routes.inputsCopy.route,
    parameters,
    settings,
    parentInput,
  )

  const onSubmit = (input: InputInit | Input) => {
    const action = () => dispatch(updateInput(input as Input))
    if (selectedInput && match.path === routes.inputsUpdate.route) {
      if (selectedInput.numOutputs)
        showConfirmation(() => {
          action()
        }, 'Current input is in use! Are you sure you want to edit it?')
      else action()
    } else dispatch(createInput(omit(input, ['id']) as InputInit))
  }

  const isLoading = (Boolean(inputId) && !selectedInput) || (Boolean(parameters.deriveFrom) && !parentInput)

  const transformPIDMapRules = (rules: PIDRule[]): PIDRule[] => {
    return rules.map<PIDRule>((r) => {
      switch (r.action) {
        case PIDRuleAction.Map:
          return { action: r.action, pid: r.pid, destPid: r.destPid }
        case PIDRuleAction.Delete:
          return { action: r.action, pid: r.pid }
        case PIDRuleAction.SetNull:
          return { action: r.action, pid: r.pid }
      }
    })
  }

  return (
    <Wrapper name="Inputs" entityName={inputId ? selectedInput?.name : 'New'}>
      <Grid container spacing={0}>
        <Pendable pending={isLoading}>
          <Formik
            component={InputForm}
            initialValues={initialState}
            onSubmit={(values) => {
              values.ports = collectPortsFromApplianceSections(values)
              const hasEncoderSettings = values.ports.some((p) => {
                const applianceFeatures = p._port?._appliance?.features ?? (p as any)._port?.appliance?.features
                const modesWithEncoderSettings =
                  applianceFeatures?.input?.modes.filter((m) => !!m.encoder).map((m) => m.mode) ?? []
                return modesWithEncoderSettings.includes(p.mode)
              })
              const transformed = formTransform(values, {
                deriveFrom: {
                  _transform: (deriveFrom: InputInit['deriveFrom']) => {
                    if (!deriveFrom?.parentInput || !values._derived) {
                      return undefined
                    }

                    const services: number[] = distinct(
                      Object.assign(deriveFrom.ingestTransform?.services ?? [])
                        // pid is a number when coming from backend and a string when coming from formik
                        .map((pid: number | string) => parseInt(pid.toString()))
                        .filter((pidOrNaN: number) => !isNaN(pidOrNaN)),
                    )

                    const ingestTransform = {
                      ...deriveFrom.ingestTransform,
                      type: 'mpts-demux',
                      services,
                    }

                    // Map PID map rules to avoid sending destPid to API when not applicable
                    // Fallback to empty rules array if pidMap is undefined
                    const pidMap = ingestTransform.pidMap
                    ingestTransform.pidMap =
                      pidMap !== undefined ? { ...pidMap, rules: transformPIDMapRules(pidMap.rules) } : { rules: [] }

                    return {
                      ...deriveFrom,
                      delay: 1000,
                      ingestTransform: ingestTransform,
                    }
                  },
                },
                thumbnailMode: { _transform: (mode: boolean) => (mode ? ThumbnailMode.core : ThumbnailMode.none) },
                videoPreviewMode: {
                  _transform: (val: string) => (values.thumbnailMode ? val : VideoPreviewMode.off),
                },
                adminStatus: { _transform: (val: boolean) => (val ? InputAdminStatus.on : InputAdminStatus.off) },
                ports: {
                  reducedBitrateThreshold: {
                    _transform: (bitrate: number | '') => (bitrate === '' ? undefined : bitrate * 1000),
                  },
                  encoderSettings: {
                    _transform: (es: Partial<EncoderSettings>) => (!hasEncoderSettings ? undefined : es),
                    totalBitrate: {
                      _transform: (bitrate: number | '') => (bitrate === '' ? undefined : bitrate * 1000000),
                    },
                    audioStreams: {
                      // TODO: Codec AES3 does not support bitrate but it is a required property.
                      _transform: (audioStream: AudioStream) => ({
                        ...audioStream,
                        bitrate: audioStream.bitrate || -1,
                      }),
                    },
                  },
                  _transform: (port: Partial<InputPort> & { _port: PhysicalPort }) => {
                    if (!port?.physicalPort) {
                      return undefined
                    }
                    const result = isIpPort(port as InputPort)
                      ? pick(port, getIpPortFormFields(port as InputPort))
                      : port.mode === ComprimatoPortMode.comprimatoNdi
                      ? pick(port, [CommonFields.physicalPort, CommonFields.mode, CommonFields.copies, 'id', 'name'])
                      : pick(port, [
                          CommonFields.physicalPort,
                          CommonFields.mode,
                          CommonFields.copies,
                          'id',
                          'encoderSettings',
                        ])
                    result.copies = !isCoreNode(values) && values._redundant ? 2 : 1
                    const omittedFields = ['region.allocatedAppliance']
                    if (port.mode === IpPortMode.generator && typeof port.port !== 'number') {
                      // TODO: Remove this when we expect all generators created before R3.14.0 have been updated.
                      omittedFields.push('address')
                    }
                    return { ...omit(result, omittedFields) }
                  },
                },
                _transform: (input: Partial<Input>) => omitBy(input, (_value: any, key: string) => key.startsWith('_')),
              })
              if (values._derived) {
                transformed.ports = undefined
              }
              if (transformed.ports?.length > 1 && transformed.ports?.[0]?.mode === IpPortMode.srt) {
                let failoverPriority: number | undefined = undefined
                const bondingMode: SrtBondingMode = (values.ports[0] as any).bondingMode
                if (bondingMode !== SrtBondingMode.none) {
                  failoverPriority = 0
                }
                for (const p of transformed.ports!) {
                  if (p.mode === IpPortMode.srt && p.srtMode !== SrtMode.rendezvous) {
                    p.failoverPriority = failoverPriority
                  }
                  if (bondingMode === SrtBondingMode.activeBackup) {
                    failoverPriority! += 1
                  }
                }
              }

              if ((values.broadcastStandard || 'none') === 'none') {
                transformed.tr101290Enabled = false
                transformed.broadcastStandard = undefined
              } else {
                transformed.tr101290Enabled = true
              }
              delete transformed.maxBitrate
              transformed.maxBitrate = transformed.maxBitrateMbps ? transformed.maxBitrateMbps * 10 ** 6 : null
              onSubmit(transformed as unknown as InputInit)
            }}
          />
        </Pendable>
      </Grid>
    </Wrapper>
  )
}
