import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Settings, Add } from '@mui/icons-material'

import { ApplianceType, IpMappingRead, IpMappingType, ListIpMappingSortableField } from 'common/api/v1/types'
import { Link } from '../common/Link'
import Table, { TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import { useAddressMappingSelector, usePageParamsFilteredSelector } from '../../utils'
import { ReduxSelectedFilters } from '../common/Filters/FilterView/SelectedFilters'
import Toolbar from '@mui/material/Toolbar'
import { mapAddressMappingFilterToUrlParam, mapUrlParamToAddressMappingFilter } from './listAddressMappingFilter'

import { useRoutes } from '../../store'

export const ListView = () => {
  const routes = useRoutes()
  const { ipMappings, loading } = usePageParamsFilteredSelector(useAddressMappingSelector)

  const tableConfig: TableConfig<IpMappingRead, ListIpMappingSortableField> = [
    {
      title: 'region',
      getValue: ({ region }) =>
        (region && (
          <Typography variant="body2" color="textSecondary">
            {region.name}
          </Typography>
        )) ||
        null,
      sorting: {
        byParameter: ListIpMappingSortableField.regionName,
      },
    },
    {
      title: 'appliance',
      getValue: ({ nics }) =>
        nics.map((n) => (
          <Link key={n.appliance.id} to={routes.appliancesUpdate({ id: n.appliance.id })} underline="hover">
            <Typography variant="body2" color="textSecondary">
              {n.appliance.name}
            </Typography>
          </Link>
        )) || null,
      sorting: {
        byParameter: ListIpMappingSortableField.applianceName,
      },
    },
    {
      title: 'interface',
      getValue: (mapping) =>
        mapping.nics.map((n) =>
          n.appliance.type === ApplianceType.thumb ? (
            <Typography key={n.id} variant="body2" color="textSecondary">
              {n.name}
            </Typography>
          ) : (
            <Link key={`${n.id}`} to={routes.interfacesUpdate({ id: n.id })} underline="hover">
              <Typography variant="body2" color="textSecondary">
                {n.name}
              </Typography>
            </Link>
          ),
        ) || null,
      sorting: {
        byParameter: ListIpMappingSortableField.interfaceName,
      },
    },
    {
      title: 'private address',
      getValue: ({ privateIp, type }) =>
        (privateIp && (
          <Typography color={type === IpMappingType.suggested ? 'textSecondary' : 'textPrimary'}>
            {privateIp}
          </Typography>
        )) ||
        null,
      sorting: {
        byParameter: ListIpMappingSortableField.privateAddress,
      },
    },
    {
      title: 'public address',
      getValue: ({ publicIp }) => publicIp,
      sorting: {
        byParameter: ListIpMappingSortableField.publicAddress,
      },
    },
    {
      title: 'internal address',
      getValue: ({ internalIp }) => internalIp,
      sorting: {
        byParameter: ListIpMappingSortableField.internalAddress,
      },
    },
    {
      title: 'inter region address',
      getValue: ({ interRegionIp }) => interRegionIp,
      sorting: {
        byParameter: ListIpMappingSortableField.interRegionAddress,
      },
    },
    {
      getValue: (mapping) => (
        <Link
          to={routes.addressMappingsUpdate({ privateIp: mapping.privateIp, region: mapping.region.id })}
          tabIndex={-1}
        >
          <IconButton edge="end" data-test-id="edit-mapping">
            {mapping.type === IpMappingType.suggested ? <Add /> : <Settings />}
          </IconButton>
        </Link>
      ),
      props: { align: 'right' },
    },
  ]

  return (
    <Wrapper name="Address mappings">
      <Toolbar disableGutters>
        <ReduxSelectedFilters
          urlParamCacheKey={'addressMappings'}
          mapUrlParamToFilterFn={mapUrlParamToAddressMappingFilter}
          mapFilterToUrlParamFn={mapAddressMappingFilterToUrlParam}
        />
        <div style={{ marginLeft: 'auto' }}>
          <Link key="addMapping" id="mappings-table-create-button" to={routes.addressMappingsNew()}>
            <Button variant="contained" color="primary">
              Add mapping
            </Button>
          </Link>
        </div>
      </Toolbar>
      <Table<IpMappingRead>
        id="mappings"
        emptyMessageComponent={<MissingContent message="No addresses to map" />}
        config={tableConfig}
        data={ipMappings}
        pending={loading}
      />
    </Wrapper>
  )
}
