import { FormikProps } from 'formik'
import {
  ApplianceFeatures,
  EncoderSettings,
  isGeneralEncoderSettings,
  MatroxPortMode,
  PortMode,
} from 'common/api/v1/types'
import GeneralEncoderDecoderSettings from '../../common/GeneralEncoderDecoderSettings'
import { get } from 'lodash'
import { useEffect } from 'react'
import { makeDefaultVaEncoderSettings } from 'common/encoderSettingsUtil'
import { EnrichedOutputPort } from '../../../api/nm-types'

function isCorrectEncoderSettingsTypeForPortMode(
  encoderSettings: EncoderSettings | undefined,
  mode: PortMode,
  applianceFeatures: ApplianceFeatures,
) {
  const isMatroxMode = mode === MatroxPortMode.matroxSdi
  const modesWithDecoderSettings = applianceFeatures.output?.modes.filter((m) => !!m.decoder).map((m) => m.mode) ?? []
  if (modesWithDecoderSettings.includes(mode) && !isMatroxMode) {
    return encoderSettings && isGeneralEncoderSettings(encoderSettings)
  }
  // Port mode has no support for encoder settings
  // or is Matrox which has its decoder settings embedded in the MatroxSdiPortForm component, presented separately
  // - should be undefined
  return encoderSettings === undefined
}

function makeEncoderSettings(mode: PortMode, applianceFeatures: ApplianceFeatures) {
  const isMatroxMode = mode === MatroxPortMode.matroxSdi
  const modesWithDecoderSettings = applianceFeatures.output?.modes.filter((m) => !!m.decoder).map((m) => m.mode) ?? []
  const hasDecoderSettings = modesWithDecoderSettings.includes(mode)
  return isMatroxMode
    ? undefined // Matrox decoder settings are embedded in the MatroxSdiPortForm component, presented separately.
    : hasDecoderSettings
    ? makeDefaultVaEncoderSettings()
    : undefined
}

interface Props {
  form: FormikProps<any>
  namePrefix: string
  applianceFeatures: ApplianceFeatures
}
const EncoderSettings = ({ form, namePrefix, applianceFeatures }: Props) => {
  const { values, setFieldValue } = form
  const logicalPort: EnrichedOutputPort = get(values, namePrefix)
  const decoderFeatures = applianceFeatures.output?.modes.find((m) => m.mode === logicalPort.mode)?.decoder
  const encoderSettings = (logicalPort as any).encoderSettings

  useEffect(() => {
    if (!isCorrectEncoderSettingsTypeForPortMode(encoderSettings, logicalPort.mode, applianceFeatures)) {
      const newEncoderSettings = makeEncoderSettings(logicalPort.mode, applianceFeatures)
      setFieldValue(`${namePrefix}.encoderSettings`, newEncoderSettings, false)
    }
  }, [encoderSettings, logicalPort.mode, setFieldValue, namePrefix])

  if (!decoderFeatures) {
    return null
  }
  if (!encoderSettings) {
    return null
  }
  if (!isCorrectEncoderSettingsTypeForPortMode(encoderSettings, logicalPort.mode, applianceFeatures)) {
    return null
  }

  return (
    <>
      <GeneralEncoderDecoderSettings
        namePrefix={`${namePrefix}.encoderSettings`}
        decoderFeatures={decoderFeatures}
        setFieldValue={setFieldValue}
      />
    </>
  )
}

export default EncoderSettings
