import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { FiberManualRecord as Circle, HelpOutline } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'

import Table, { type TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import { Api, type AppDispatch } from '../../store'
import { enqueueErrorSnackbar } from '../../redux/actions/notificationActions'

type State = Awaited<ReturnType<typeof Api.statusApi.listStatus>>
type TableRow = State['items'][number]

const StatusIndicator = (status: TableRow['status']) => {
  switch (status) {
    case 'OK':
      return <Circle sx={{ color: (theme: Theme) => theme.palette.success.main }} />
    case 'WARN':
      return <Circle sx={{ color: (theme: Theme) => theme.palette.warning.main }} />
    case 'ERROR':
      return <Circle sx={{ color: (theme: Theme) => theme.palette.error.main }} />
    default:
      throw new Error('unreachable')
  }
}

export const StatusList = () => {
  const [status, setStatus] = useState<State | null>(null)
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    const listStatus = async () => {
      try {
        const status = await Api.statusApi.listStatus()
        setStatus(status)
      } catch (e) {
        dispatch(enqueueErrorSnackbar({ error: e, operation: 'list status' }))
      }
    }
    listStatus()
  }, [dispatch])

  const tableConfig: TableConfig<TableRow> = [
    {
      title: 'name',
      getValue: ({ name, description }) => (
        <Box sx={{ display: 'flex', whiteSpace: 'nowrap' }}>
          {name}
          <Tooltip title={description}>
            <HelpOutline sx={{ marginLeft: 1 }} />
          </Tooltip>
        </Box>
      ),
    },
    {
      title: 'status',
      getValue: ({ status }) => StatusIndicator(status),
    },
    {
      title: 'issues',
      getValue: ({ issues }) => (
        <Box sx={{ whiteSpace: 'nowrap' }}>
          {issues?.map((issue) => {
            return <Box key={issue.name}>{JSON.stringify(issue)}</Box>
          })}
        </Box>
      ),
    },
  ]

  return (
    <Wrapper name="Status">
      <Table<TableRow>
        emptyMessageComponent={<MissingContent message="No status available" />}
        config={tableConfig}
        data={status?.items ?? []}
        pending={status === null}
      />
    </Wrapper>
  )
}
