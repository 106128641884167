import { CoordinatesMapbox } from 'common/api/v1/types'
import EventEmitter from 'events'

export interface PopupProps {
  content: React.ReactNode
  coordinates: CoordinatesMapbox
}

export class FeatureEvents {
  public appliances: EventEmitter = new EventEmitter()
}
