import { FC } from 'react'
import { Field, FieldProps, getIn } from 'formik'
import MenuItem from '@mui/material/MenuItem'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import GridItem, { GridItemProps, omitGridProps } from './GridItem'
import BackendValidation from '../../common/Form/BackendValidation'
import { validate } from '../../../utils'

const Comp = (props: FieldProps & TextFieldProps & SelectProps) => {
  const fieldError = getIn(props.form.errors, props.field.name)
  const showError = getIn(props.form.touched, props.field.name) && Boolean(fieldError)
  const muiProps: TextFieldProps = {
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    ...props.field,
    ...props,
  }
  const hasEmpty = !!(props.options || []).find((option) => typeof option === 'object' && option.value === '')
  const selectProps = {
    displayEmpty: hasEmpty,
  }
  const labelProps = {
    shrink: hasEmpty || undefined,
  }
  return (
    <>
      <TextField {...muiProps} SelectProps={selectProps} InputLabelProps={labelProps} defaultValue="" />
      <BackendValidation form={props.form} name={props.field.name} />
    </>
  )
}

export interface RichOption {
  name: string
  value?: string | number
  disabled?: boolean
  tooltip?: string
}
type Option = RichOption | string | number
interface SelectProps extends GridItemProps {
  name?: string
  label?: string
  options?: Array<Option>
  required?: boolean
  disabled?: boolean
  validators?: Parameters<typeof validate>[0]
  variant?: TextFieldProps['variant']
  hiddenLabel?: boolean
  sx?: TextFieldProps['sx']
}

/**
 * Simple select
 * @param props {
 *   name?: formik's name
 *   label?: label to show
 *   validators?: validateJs validations plus self defined ones from utils/validation
 *   required?: adds validation
 *   options?: array of string numbers or complex objects with name, value disabled state and tooltip
 * }
 */
const Select: FC<SelectProps> = (props) => {
  const { validators = {}, variant = 'outlined', hiddenLabel = false, sx } = props
  if (props.required) validators.presence = true
  return (
    <GridItem {...props}>
      <Field
        select
        label={hiddenLabel ? undefined : props.name}
        component={Comp}
        margin="normal"
        fullWidth
        variant={variant}
        hiddenLabel={hiddenLabel}
        sx={sx}
        validate={validate(validators)}
        data-test-id={`select-${props.name ?? props.label}`}
        {...{
          ...omitGridProps(props),
          // options are used by unit tests
          options: props.options?.map((o) => (typeof o === 'object' && 'name' in o ? o.name : o)),
        }}
      >
        {props.options !== undefined &&
          props.options.map((option: Option) => {
            const opt = typeof option !== 'object' ? ({ name: option } as RichOption) : option
            return (
              <MenuItem value={'value' in opt ? opt.value : opt.name} key={opt.name} disabled={Boolean(opt.disabled)}>
                <span style={{ fontStyle: 'value' in opt && opt.value === undefined ? 'italic' : undefined }}>
                  {opt.name}
                </span>
                {!!opt.tooltip && (
                  <Typography variant="body2" component="span">
                    &nbsp;({opt.tooltip})
                  </Typography>
                )}
              </MenuItem>
            )
          })}
      </Field>
    </GridItem>
  )
}

export default Select
