import React, { useState, useEffect, Fragment, SyntheticEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar, CloseReason } from 'notistack'
import Box from '@mui/material/Box'
import Button from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import { Close, ExpandMore, ExpandLess } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'

import {
  removeSnackbar,
  closeSnackbar as closeSnackbarAction,
  Notification,
  NotificationErrorData,
} from '../../redux/actions/notificationActions'
import { GlobalState, AppDispatch } from '../../store'
import { Link } from './Link'

const styles = {
  wrapper: {
    maxWidth: 420,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child': {
      flexGrow: 1,
    },
  },
  button: {
    color: (theme: Theme) => theme.palette.common.black,
    opacity: 0.6,
  },
}

/**
 * Component to subscribe and control Redux state for snackbars
 * Every enqueue/dismiss for notification is basically push/pop from the notifications array in Redux
 */
const Notifier = () => {
  const dispatch = useDispatch<AppDispatch>()
  const notifications: Notification[] = useSelector(
    ({ notificationsReducer: notifications }: GlobalState) => notifications,
  )
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  // Basic string message
  const enqueueBasicSnackbar = (notification: Notification) => {
    enqueueSnackbar(notification.message, {
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      ...notification.options,
      action: defaultAction(notification.options.key || ''),
      onClose: defaultOnClose(notification),
      preventDuplicate: true,
    })
  }

  // A new version is available, i.e. the client version is behind reported backend version
  const enqueueNewVersionSnackbar = (notification: Notification) => {
    enqueueSnackbar('A new version is available', {
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      action: () => (
        <Fragment>
          <Button color="primary" size="small" onClick={() => window.location.reload()} data-reload-page-new-version>
            Reload
          </Button>
          <Button onClick={() => dispatch(closeSnackbarAction(notification.options.key || ''))}>
            <Close sx={styles.button} />
          </Button>
        </Fragment>
      ),
      onClose: defaultOnClose(notification),
      ...notification.options,
      preventDuplicate: true,
    })
  }

  // String message encapsulated in a link
  const enqueueLinkSnackbar = (notification: Notification) => {
    const message = (
      <Link to={''} underline="hover" style={{ color: 'inherit' }} muiStyle={{ color: 'inherit' }}>
        {notification.message}
      </Link>
    )
    enqueueSnackbar(message, {
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      action: defaultAction(notification.options.key || ''),
      onClose: defaultOnClose(notification),
      ...notification.options,
      preventDuplicate: true,
    })
  }

  // Fatal errors, encapsulating ErrorSnackbar
  const enqueueErrorSnackbar = (notification: Notification) => {
    enqueueSnackbar(<ErrorSnackbar error={notification.data as NotificationErrorData} />, {
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      action: defaultAction(notification.options.key || ''),
      onClose: defaultOnClose(notification),
      ...notification.options,
      preventDuplicate: true,
    })
  }

  const defaultAction = (key: string | number) => (
    <Button onClick={() => dispatch(closeSnackbarAction(key))}>
      <Close sx={styles.button} data-test-id="close-snackbar" />
    </Button>
  )

  const defaultOnClose =
    (notification: Notification) => (event: SyntheticEvent<Element> | null, reason: CloseReason) => {
      if (notification.options.onClose) {
        notification.options.onClose(event, reason)
      }
      if (reason !== 'clickaway') {
        dispatch(removeSnackbar(notification.options.key || ''))
      }
    }

  useEffect(() => {
    notifications.forEach((notification) => {
      const key = notification.options.key || ''

      if (notification.dismissed) {
        closeSnackbar(key)
        dispatch(removeSnackbar(key))
        return
      }

      switch (notification.type) {
        case 'basic':
          enqueueBasicSnackbar(notification)
          break
        case 'newVersionAvailableSnackbar':
          enqueueNewVersionSnackbar(notification)
          break
        case 'linkSnackbar':
          enqueueLinkSnackbar(notification)
          break
        case 'fatalError':
          enqueueErrorSnackbar(notification)
          break
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications])

  return null
}

/**
 * Specific snackbar to display error
 * @param error - NotificationErrorData
 */
export const ErrorSnackbar: React.FC<{ error: NotificationErrorData }> = ({ error }) => {
  const [collapsed, setCollapsed] = useState(true)
  let errorText = error.text
  let errorTitle = error.text
  if (error.httpStatusCode === 403) {
    errorTitle = 'Insufficient rights'
    errorText = 'Insufficient privilege for this user interaction, please contact your administrator'
  }
  if (error.httpStatusCode === 409) {
    errorTitle = 'Conflict'
  }

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.container}>
        <Typography data-test-id="title">{errorTitle}</Typography>
        <Button onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <ExpandMore sx={styles.button} /> : <ExpandLess sx={styles.button} />}
        </Button>
      </Box>
      <Collapse in={!collapsed}>
        {errorText !== errorTitle && (
          <Typography component="div" variant="body2">
            {errorText}
          </Typography>
        )}
        <Typography component="div" variant="body2" data-test-id="details" style={{ whiteSpace: 'pre-wrap' }}>
          {formatErrorDetails(error.details)}
        </Typography>
      </Collapse>
    </Box>
  )
}

function formatErrorDetails(details: any) {
  if (!details) {
    return ''
  }
  if (typeof details === 'string') {
    return details
  }
  let detailsText = ''
  if (Array.isArray(details)) {
    detailsText += 'Details: '
    for (const detail of details) {
      detailsText += `${formatDetail(detail)}\n`
    }
  }
  return detailsText
}

function formatDetail(detail: any) {
  if (!detail) {
    return ''
  }
  if (typeof detail === 'string') {
    return detail
  }
  if (typeof detail.reason === 'string') {
    return detail.reason
  }
  return JSON.stringify(detail)
}

export default Notifier
