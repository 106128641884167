import React from 'react'
import { useHistory } from 'react-router-dom'
import { DispatchProp, useDispatch } from 'react-redux'
import { GroupAdd, Settings, Input, Delete, Info, CallSplit, MoreVert, Stop, PlayArrow } from '@mui/icons-material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'

import { EnrichedInput } from '../../../api/nm-types'
import { styles } from '../../../Common'
import { ExperimentalFeatures, InputAdminStatus, Role, User } from 'common/api/v1/types'
import { DraftActions, draftInputs, enableInputs } from '../../../redux/actions/inputsActions'
import { AppDispatch } from 'src/store'

import { useRoutes } from '../../../store'

interface ActionMenuProps {
  input: EnrichedInput
  user: User
  dispatch: DispatchProp['dispatch']
  experimentalFeatures?: ExperimentalFeatures
}

interface ToggleAdminStatusButtonProps {
  input: EnrichedInput
  handleItemClick: (event: React.MouseEvent<HTMLElement>) => void
}

const ToggleAdminStatusButton = ({ input, handleItemClick }: ToggleAdminStatusButtonProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    handleItemClick(event)

    if (input.adminStatus === InputAdminStatus.on) {
      dispatch(draftInputs({ action: DraftActions.disable, inputs: [input] }))
    } else {
      dispatch(enableInputs([input.id]))
      dispatch(draftInputs({ inputs: [] }))
    }
  }

  return (
    <MenuItem data-test-id="toggle-admin-status-input" aria-label="toggle admin status" onClick={onClick}>
      <ListItemIcon>{input.adminStatus === InputAdminStatus.on ? <Stop /> : <PlayArrow />}</ListItemIcon>
      <ListItemText primary={input.adminStatus === InputAdminStatus.on ? 'Disable' : 'Enable'} />
    </MenuItem>
  )
}

export const ActionMenu = (props: ActionMenuProps) => {
  const routes = useRoutes()
  const { input, dispatch, user, experimentalFeatures } = props
  const isOwner = user.group === input.owner || user.role === Role.super
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const history = useHistory()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleItemClick = (event: React.MouseEvent<HTMLElement>, route?: string) => {
    event.stopPropagation()
    setAnchorEl(null)
    if (route) {
      history.push(route)
    }
  }

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <IconButton
        aria-label="service overview button"
        onClick={(e) => handleItemClick(e, routes.service({ id: input.id }))}
        data-test-id={`service-overview-btn`}
        disabled={!input.ports?.length && !input.deriveFrom}
      >
        <Tooltip title={'Overview'} placement="top">
          <Info />
        </Tooltip>
      </IconButton>
      <IconButton
        aria-label="open input action menu"
        data-test-id="open-input-action-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ marginLeft: '-8px' }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        data-test-id="input-action-menu"
        data-is-open={open ? 'true' : 'false'}
        keepMounted
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '35ch',
          },
        }}
      >
        {isOwner && (
          <MenuItem
            onClick={(e) => handleItemClick(e, routes.inputsUpdate({ id: input.id }))}
            data-test-id="edit-input"
            aria-label="edit"
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </MenuItem>
        )}
        {isOwner && (
          <MenuItem
            aria-label="share"
            data-test-id={`share-input`}
            disabled={!isOwner}
            onClick={(e) => {
              handleItemClick(e)
              dispatch(draftInputs({ inputs: [input], action: DraftActions.share }))
            }}
          >
            <ListItemIcon>
              <GroupAdd />
            </ListItemIcon>
            <ListItemText primary="Share" />
          </MenuItem>
        )}
        {isOwner && <ToggleAdminStatusButton input={input} handleItemClick={handleItemClick} />}
        <MenuItem
          aria-label="send to output"
          data-test-id={`send-to-output`}
          disabled={!input.canSubscribe}
          onClick={(e) => {
            handleItemClick(e)
            dispatch(draftInputs({ inputs: [input], action: DraftActions.send }))
          }}
        >
          <ListItemIcon>
            <Input />
          </ListItemIcon>
          <ListItemText primary="Send to output" />
        </MenuItem>
        {experimentalFeatures && experimentalFeatures.ExtMptsDerivedInput && !input.deriveFrom && isOwner && (
          <MenuItem
            onClick={(e) => handleItemClick(e, routes.inputsDerive({ id: input.id }))}
            data-test-id="derive-from"
          >
            <ListItemIcon>
              <CallSplit />
            </ListItemIcon>
            <ListItemText primary="Create derived input" />
          </MenuItem>
        )}
        {isOwner && (
          <MenuItem
            aria-label="delete"
            data-testid="delete-input"
            sx={(theme) => ({ ...styles.error(theme), '&:hover': styles.error })}
            onClick={(e) => {
              handleItemClick(e)
              dispatch(draftInputs({ inputs: [input], action: DraftActions.delete }))
            }}
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </MenuItem>
        )}
      </Menu>
    </Box>
  )
}
